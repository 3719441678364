import { faSearch, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import TablePagination from "../../../components/TablePagination";
import IMeetingOpenDTO from "../../../models/IMeetingOpenDTO";
import Meeting from "../../../models/Meeting";
import UserLogin from "../../../models/UserLogin";
import api from "../../../services/api";
import { authenticationService } from "../../../services/auth.service";
import { displayDateTime } from "../../../utils/masks";

import "./meeting-list.css";

interface MemberListDTO {
  id: number;
  name: string;
  ecclesiasticalFunction: any;
  congregation: any;
}

export default function DashboardSecretary() {
  const [isReadOnly, setReadOnly] = useState<boolean>();
  const [isAuxSec, setAuxSec] = useState<boolean>();
  const [refresh, setRefresh] = useState<boolean>();
  const [totalMeetings, setTotalMeetings] = useState(0);
  const [meetingOpened, setMeetingOpened] = useState<IMeetingOpenDTO>();
  const navigate = useNavigate();

  const columns = [
    {
      name: "Reunião",
      sortable: true,
      grow: 2,
      cell: (row: Meeting) => <span>{row.title}</span>,
    },
    {
      name: "Fechada",
      sortable: true,
      cell: (row: Meeting) => <span>{displayDateTime(row.timeClose)}</span>,
    },
    {
      name: "Ações",
      button: true,
      sortable: false,
      cell: (row: MemberListDTO) => (
        <div>
          {
            <Link to={`/reuniao/${row.id}`} 
              uk-tooltip="title: Visualizar"
              className="uk-margin-right"
            >
              <FontAwesomeIcon icon={faSearch} />
            </Link>
          }
          {
            <Link
              to={`/reuniao/${row.id}/membros`}
              uk-tooltip="title: Membros Presentes"
              className="uk-margin-right"
            >
              <FontAwesomeIcon icon={faUsers} />
            </Link>
          }
        </div>
      ),
    },
  ];

  useEffect(() => {
    setReadOnly(!authenticationService.canChange());
    setAuxSec(authenticationService.isAuxSec());
    init();
  }, []);

  const init = async () => {};

  const fetchMeetings = async (page: number, limit: number) => {
    try {
      setTotalMeetings(0);
      const response = await api.get(`meetings`, {
        params: {
          page: page,
          limit: limit,
          user: authenticationService.isAuxSec()
            ? (authenticationService.currentUserValue as UserLogin).id
            : null,
        },
      });

      setTotalMeetings(response.data.total);

      const { data: dataMeeting } = await api.get("meetings/open");

      setMeetingOpened(dataMeeting.item);

      return response;
    } catch {
      return { data: [], total: 0 };
    }
  };

  return (
    <div id="page-meeting-list">
      <div
        className="uk-card uk-card-default uk-card-small uk-card-hover uk-margin-bottom"
      >
        <div className="uk-card-header">
          <div className="uk-grid-small uk-flex-middle" uk-grid="true">
            <div className="uk-width-expand">
              <h3 className="uk-card-title uk-margin-remove-bottom">
                Reuniões de Obreiros
              </h3>
            </div>
            <Link
              className="uk-button uk-button-primary uk-button-small"
              to="/secretaria"
            >
              Voltar
            </Link>
          </div>
        </div>
        <div className="uk-card-body">
          Reuniões: {totalMeetings}
          {meetingOpened && (
            <Link
              className="uk-button uk-button-primary uk-button-small uk-margin-left"
              to="/reuniao/em-andamento"
            >
              Acessar reunião em andamento <span uk-icon="arrow-right"></span>
            </Link>
          )}
          <TablePagination
            columns={columns}
            fetchData={fetchMeetings}
            refresh={refresh}
          />
        </div>
      </div>
    </div>
  );
}
