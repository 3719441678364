import React from "react";
import Modal from "../modal";

interface PdfImageModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileUrl: string; // URL PDF or image
}

const ViewerModal: React.FC<PdfImageModalProps> = ({ isOpen, onClose, fileUrl }) => {
  const isPdf = fileUrl.toLowerCase().endsWith(".pdf");

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={()=> onClose()}
      contentLabel="Visualizador de Arquivo"
      style={{
        content: {
          width: "80%",
          height: "80%",
          margin: "auto",
          overflow: "hidden"      
        }
      }}
    >
      <button onClick={()=> onClose()}
        style={{ position: "absolute", right: 10, top: 10, zIndex: 999 }}
        className="uk-button uk-button-small uk-button-primary"
      >
        Fechar
      </button>
      
      {isPdf ? (
        <iframe
          src={`https://docs.google.com/gview?url=${encodeURIComponent(fileUrl)}&embedded=true`}
          width="100%"
          height="100%"
          style={{ border: "none", marginTop: 20 }}
        />
      ) : (
        <img src={fileUrl} alt="Visualização"
          style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain", display: "block", margin: '0 auto' }} 
        />
      )}
    </Modal>
  );
};

export default ViewerModal;