import { Link, useNavigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../../services/api";
import toastOptions from "../../../utils/toastOptions";
import { authenticationService } from "../../../services/auth.service";
import DialogConfirm from "../../../components/DialogConfirm";
import IPresenceOpenDTO from "../../../models/IPresenceOpenDTO";
import { displayDateTime } from "../../../utils/masks";
import PresenceList from "../../presence/presence-list/PresenceList";

export default function DashboardEvent() {
  const [totalPeople, setTotalPeople] = useState<number>();
  const [hasPresenceOpen, setPresenceOpen] = useState<boolean | undefined>(undefined);
  const [openConfirm, setConfirmeOpen] = useState<boolean>(false);
  const [isReadOnly, setReadOnly] = useState<boolean>(authenticationService.isReadOnly());
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const navigate = useNavigate();
  const [presenceOpened, setPresenceOpened] = useState<IPresenceOpenDTO>();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    fetchEventPeople();

    try{
      const { data } = await api.get("/events/1/presence/open");
  
      setPresenceOpen(
        (authenticationService.canChange() && data.success && data.item?.presenceList) ? true : false
      );

      setPresenceOpened(data.item);
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.warning(m, toastOptions);
      });

      setTotalPeople(0);
    };
  }

  const fetchEventPeople = async () => {
    try {
      let params = {
        page: 1,
        limit: 1,
      } as any;

      const { data } = await api.get(`/events/people`, {
        params: params,
      });

      setTotalPeople(data.total);

    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.warning(m, toastOptions);
      });

      setTotalPeople(0);
    }
  };

  const handlePresence = async () => {
    setIsRunning(true);
    try {
      const { data } = await api.post("/events/1/presence");
      setConfirmeOpen(false);
      
      navigate("/eventos/em-andamento");
    } catch (e: any) {
      e.messages?.forEach((m: string) => {
        toast.error(m, toastOptions);
      });
    } finally {
      setIsRunning(false);
    }
  }
  
  return (
    <div
      id="page-dashboad-event"
      className="uk-section uk-section-muted uk-padding-small"
    >
      <div className="uk-container">
        <div className="uk-grid-small uk-margin-bottom" uk-grid="true">
          <div className="uk-width-1-2@m">
            <div className="uk-card uk-card-primary uk-card-small uk-card-hover">
              <div className="uk-card-header">
                <div className="uk-grid-small uk-flex-middle" uk-grid="true">
                  <div className="uk-width-expand">
                    <h3 className="uk-card-title uk-margin-remove-bottom">Inscritos • EBO 2024</h3>
                  </div>
                </div>
              </div>
              <div className="uk-card-body">
                {
                  (totalPeople == undefined) ? 
                    <div uk-spinner="true; ratio:2">Carregando...</div>
                  :
                    <h1 className="uk-heading-xlarge">{totalPeople}</h1>
                }
              </div>
              <div className="uk-card-footer">
                <Link to="/eventos/pessoas">Visualizar Inscritos <span uk-icon="arrow-right"></span></Link>
              </div>
            </div>
          </div>
          <div className="uk-width-1-2@m">
            <div className="uk-grid" uk-grid="true">
              <div className="uk-width-1-2@m">
                <div className="uk-card uk-card-body uk-card-success uk-flex uk-flex-middle uk-flex-center uk-card-hover">
                  <div className="uk-inline-clip uk-transition-toggle">
                    <Link to="/eventos/impressoes" className="uk-button">
                      <div className="uk-transition-scale-up uk-transition-opaque">
                        <span uk-icon="icon: print; ratio: 2"></span>
                        <br/>
                        Impressão
                      </div>            
                    </Link>
                  </div>
                </div>
              </div>
              <div className="uk-width-1-2@m">
                <div className="uk-card uk-card-warning uk-card-hover">
                  <div className="uk-card-body">
                    <div className="uk-inline-clip uk-transition-toggle uk-flex uk-flex-middle uk-flex-center">
                      {
                        hasPresenceOpen ? (
                          <Link to="/eventos/em-andamento" className="uk-button">
                            <div className="uk-transition-scale-up uk-transition-opaque">
                              <span uk-icon="icon: sign-out; ratio: 2"></span>
                              <br/>
                              Presenças
                            </div>                             
                          </Link>
                        ) : (
                          <div className="uk-button" onClick={()=> { if(!isReadOnly) setConfirmeOpen(true); } }>
                            <div className="uk-transition-scale-up uk-transition-opaque">
                              {
                                isRunning ? <div className="uk-margin-small-left" uk-spinner="true; ratio:0.5"></div> :
                                <div className="uk-text-white">
                                  <span uk-icon="icon: sign-out; ratio: 2"></span>
                                  <br/>
                                  <label>Presenças</label>
                                </div>
                              }
                            </div> 
                          </div>
                        )
                      }
                    </div>
                    {
                      hasPresenceOpen && 
                        <div className="uk-margin-medium-top">
                          <label>
                            Iniciada em {displayDateTime(presenceOpened?.presenceList.createdDate)}
                          </label>
                          <br/>
                          <label>
                            {presenceOpened?.qtyPeople} pessoa(s) presente(s)
                          </label>
                        </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-width-1-2@m">
            <PresenceList />
          </div>
        </div>
      </div>
      <DialogConfirm
        open={openConfirm}
        text={[
            `Deseja abrir uma nova lista de presença neste evento?`,
            `Se sim clique em [Confirmar], se não clique em [Cancelar] e tente novamente.`
        ]}
        title="Nova Lista"
        handleConfirmation={handlePresence}
        afterClose={() => {setConfirmeOpen(false);}}                
      ></DialogConfirm>
    </div>
  );
}
